// Leave this at the top of the file.  There should only be one of these.  If you need to add other document ready calls then wrap them in a function that is called here
$(function () {
  initDirtyForm();

  applyMasks();

  setupSearch();

  setupRecentListings();

  initCancelButtonConfirmMessage();

  initDeleteButtonConfirmMessage();

  if (typeof initConcurrencyHub === "function") {
    initConcurrencyHub();
  }

  initFeedbackForm();

  //Prevents a double click on form submits
  $("form").submit(function () {
    if ($(this).valid()) {
      $(":submit").prop("disabled", true);
    }
  });
});

//#region Global variables

// This controls the message that is displayed by the global delete handler
var deleteRecordType = "";

//#endregion

//#region Cancel Button Comfirm Message

function initCancelButtonConfirmMessage() {
  $(".cancelBtn").click(function (e) {
    var isDirty = isFormDirty();
    var cancelUrl = $(this).attr("data-cancel-url");

    if (isDirty) {
      $.SmartMessageBox(
        {
          title:
            '<i class="fa fa-exclamation-triangle txt-color-yellow"></i>  Are you sure you want to cancel your changes?',
          content:
            "You haven't saved your changes yet. Do you want to cancel without saving?",
          buttons: "[Yes][No]",
        },
        function (buttonPressed) {
          if (buttonPressed == "Yes") {
            this.isDirtyOverridden = true;
            window.location = cancelUrl;
          }
        }
      );
    } else {
      window.location = cancelUrl;
    }
  });
}

//#endregion

//#region Delete Buttons Confirm Message

function initDeleteButtonConfirmMessage() {
  $(document).on("click", ".deleteButton", function (e) {
    var deleteUrl = $(this).attr("data-delete-url");

    e.preventDefault();

    $.SmartMessageBox(
      {
        title: '<i class="fa fa-trash-o txt-color-red"></i>  Confirm Delete',
        content:
          "Are you sure you want to delete the " + deleteRecordType + "?",
        buttons: "[Yes, Delete][No]",
      },
      function (buttonPressed) {
        if (buttonPressed == "Yes, Delete") {
          post(deleteUrl);
        }
      }
    );
  });
}

//#endregion

//#region Data Tables
function extendDataTablesSettings(settings) {
  var extended = $.extend(
    {},
    {
      //Smart Admin styles
      sDom:
        "<'dt-toolbar'<'col-xs-12 col-sm-6'f><'col-xs-12 col-sm-6'l>r>" +
        "t" +
        "<'dt-toolbar-footer'<'col-xs-12 col-sm-6'i><'col-xs-12 col-sm-6'p>>",
      oLanguage: {
        sLengthMenu: "_MENU_",
        sSearch: "",
        sInfo:
          "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> entries",
        sInfoEmpty:
          "<span class='text-danger'>Showing 0 to 0 of 0 entries</span>",
        sSearch:
          "<span class='input-group-addon'><i class='fa fa-search'></i></span> ",
      },
      sWrapper: "dataTables_wrapper form-inline",
      //End Smart Admin styling
    },
    settings
  );

  return extended;
}

//#endregion

//#region FeedbackForm

function initFeedbackForm() {
  $("#feedbackForm").validate(
    extendValidationSettings({
      rules: {
        FeedbackName: {
          required: true,
        },
        FeedbackEmail: {
          required: true,
        },
        FeedbackBody: {
          required: true,
        },
      },
      messages: {
        FeedbackName: {
          required: "Name is required.",
        },
        FeedbackEmail: {
          required: "Email is required.",
        },
        FeedbackBody: {
          required: "Feedback is required.",
        },
      },
      errorContainer: "#feedbackErrorMessageBox",
      errorLabelContainer: "#feedbackErrorList",
      submitHandler: function () {
        var form = JSON.stringify($("#feedbackForm").serializeObject());

        $.postJson("/api/ReportIssue", form, function (result) {
          if (result.success) {
            $("#feedbackForm").trigger("reset");
            $("#feedbackModal").modal("hide");
          } else {
            alert("Feedback wasn't successfull");
          }
        });
      },
    })
  );
}

//#endregion

//#region Recent Listings

function setupRecentListings() {
  $("#ClearRecentListings").click(function () {
    $.postJson(
      "/api/DeleteUserAccountRecentListings",
      null,
      function (success) {
        if (success) {
          $("#recentListings > .recentListing").remove();
        }
      }
    );
  });
}

//#endregion

$(document).ready(function () {
  $(".select2").select2();
});
